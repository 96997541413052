var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "groups-container"
  }, _vm._l(Array.from(_vm.companyGroups.entries()).entries(), function (_ref) {
    var index = _ref[0],
      _ref$ = _ref[1],
      id = _ref$[0],
      group = _ref$[1];
    return _c('div', {
      key: 'group-card-' + index,
      staticClass: "group-card"
    }, [_vm.editingGroupId !== id ? _c('div', {
      staticClass: "group-card-read"
    }, [_c('div', {
      staticClass: "group-card-information"
    }, [!group.isDefault ? _c('p', {
      staticClass: "content-semibold"
    }, [_vm._v(_vm._s(group.name))]) : _vm._e(), group.isDefault ? _c('p', {
      staticClass: "content-semibold"
    }, [_vm._v(" " + _vm._s(group.name + ' (' + _vm.$t("Default") + ')') + " ")]) : _vm._e(), _c('p', {
      staticClass: "gray-text",
      staticStyle: {
        "font-size": "14px"
      }
    }, [_vm._v(" " + _vm._s(_vm.findGroupAdmin(group)) + " ")])]), _c('BaseButton', {
      attrs: {
        "display-key": 'name',
        "display-value": 'value',
        "custom-style": 'cursor: pointer; height: 20px',
        "icon-left": 'dot_menu_gray_20_20.svg',
        "items": _vm.groupCardDropdownItems(group)
      },
      on: {
        "itemSelected": function (value) {
          return _vm.groupCardDropdownItemSelected(id, group, value, index);
        }
      }
    })], 1) : _vm._e(), _c('transition', {
      attrs: {
        "name": "slide-fade"
      }
    }, [_vm.editingGroupId === id ? _c('div', {
      ref: 'group-card-' + id,
      refInFor: true,
      staticClass: "group-card-edit"
    }, [_c('div', {
      staticClass: "group-card-edit-fields-container"
    }, [_c('TaskTextInput', {
      attrs: {
        "placeholder": _vm.$t('GroupTitle')
      },
      model: {
        value: group.name,
        callback: function ($$v) {
          _vm.$set(group, "name", $$v);
        },
        expression: "group.name"
      }
    }), _c('p', {
      staticClass: "content-semibold"
    }, [_vm._v(" " + _vm._s(_vm.$t("Users")) + " (" + _vm._s(Object.values(group.users).length) + ") ")])], 1), _c('div', {
      staticClass: "group-card-table-container"
    }, [_c('CustomTable', {
      attrs: {
        "columns": _vm.groupTableColumns,
        "rows": Object.entries(group.users),
        "draggable": true
      },
      on: {
        "closed": function ($event) {
          _vm.editingGroupId = null;
        },
        "updated": function ($event) {
          return _vm.updateGroup(group);
        }
      }
    })], 1)]) : _vm._e()]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
      attrs: {
        "custom-message": _vm.deleteMessage,
        "removing": false
      },
      on: {
        "canceled": function ($event) {
          _vm.isDeleteModalOpen = false;
        },
        "approved": _vm.deleteGroup
      }
    }) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }