<template>
  <div class="header" style="background-color: white">
    <v-row class="title-row">
      <h3 class="header-title">{{ $t("Admin") }}</h3>
      <AddNewButton />
    </v-row>
    <v-row
      style="width: 100%; height: 42px; margin-top: 0; padding-left: 104px"
    >
      <div
        v-bind:class="{
          'active-view': activeView === 'Users',
        }"
        @click="activeView = 'Users'"
        id="usersView"
      >
        <p
          v-bind:class="{
            'active-view-font': activeView === 'Users',
          }"
          class="content-small view-link"
        >
          {{ $t("Users") }} ({{ allUsersCount }})
        </p>
      </div>
      <div
        v-bind:class="{
          'active-view': activeView === 'Settings',
        }"
        @click="changeFromUsersView('Settings')"
        id="settingsView"
        v-if="user.access.accessLevel === 'company_admin'"
      >
        <p
          v-bind:class="{
            'active-view-font': activeView === 'Settings',
          }"
          class="content-small view-link"
        >
          {{ $t("Settings") }}
        </p>
      </div>

      <div
        v-bind:class="{
          'active-view': activeView === 'UserGroups',
        }"
        @click="changeFromUsersView('UserGroups')"
        id="userGroupsView"
      >
        <p
          v-bind:class="{
            'active-view-font': activeView === 'UserGroups',
          }"
          class="content-small view-link"
        >
          {{ $t("UserGroups") }}
        </p>
      </div>
    </v-row>
    <HeaderToolbar v-if="activeView === 'Users'" :module="'admin'">
      <button
        class="outlined-small-button"
        name="add-user-button"
        style="background-color: black !important"
        @click="changeAddUserPanelStatus"
      >
        <img
          src="../../assets/images/plusSign.svg"
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
          alt=""
        />
        <p
          style="
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg)
              brightness(103%) contrast(102%);
          "
        >
          {{ $t("AddUser") }}
        </p>
      </button>
      <!--   <button
        @click="editLimits"
        class="outlined-small-button"
      >
        <img
          src="../../assets/images/pencil.svg"
          alt=""
        />
        <p
        >
          {{ $t("EditLimits") }}
        </p>
      </button>
 -->
      <img class="point-separator" src="../../assets/images/•.svg" alt="" />

      <div style="margin-left: 0px">
        <v-text-field
          autocomplete="off"
          outlined
          v-model="search"
          hide-details
          :id="'search-bar'"
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search') + '...'"
          style="background: white"
        >
        </v-text-field>
      </div>
      <div @click="toggleActiveUsers" class="toggle-wrapper">
        <img
          style="cursor: pointer"
          :src="
            onlyActiveUsers
              ? require('../../assets/images/toggle-active.svg')
              : require('../../assets/images/toggle-inactive.svg')
          "
          alt=""
        />
        <p class="content-normal">
          {{ $t("OnlyActiveUsers") }} ({{ activeUsers.length }})
        </p>
      </div>
    </HeaderToolbar>
  </div>
</template>

<script>
import AddNewButton from "../common/AddNew";
import HeaderToolbar from "@/components/common/HeaderToolbar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminHeader",
  components: {
    AddNewButton,
    HeaderToolbar,
  },
  data() {
    return {
      activeView: "Users",
      view: "Kanban",
      search: "",
      addUserPanelOpen: false,
      onlyActiveUsers: false,
      allUsersCount: 0,
      filteredUsers: []
    };
  },
  created() {
    this.allUsersCount = this.colleagues.length;
    this.toggleActiveUsers()
  },
  mounted() {
  },
  watch: {
    activeView: function (val) {
      this.$emit("viewChanged", val);
    },
    search: function (val) {
      this.$emit("searchInput", val);
    },
    /* editLimits: function(val) {
      this.$emit("editMode", val)
    } */
  },
  computed: {
    ...mapGetters(["users", "user"]),
    activeUsers() {
      return this.colleagues.filter((user) => {
        return user.userStatus === "Active";
      });
    },
    colleagues() {
      if (this.user.access.company.isAdmin) {
        return this.users;
      } else {
        return this.users.filter(user =>
            Object.keys(user.access.groups)
                .some((groupId) => this.user.access.groups[groupId]?.isAdmin)
        );
      }
    }
  },
  methods: {
    ...mapActions(["getUsers"]),
    async toggleActiveUsers() {
      this.onlyActiveUsers = !this.onlyActiveUsers;
      this.$emit("onlyActiveUsers", this.onlyActiveUsers)
      if (this.onlyActiveUsers) {
        this.filteredUsers = this.activeUsers
      } else {
        await this.getUsers();
      }
    },
    changeFromUsersView(view) {
      this.activeView = view;
    },
    changeAddUserPanelStatus() {
      this.addUserPanelOpen = true;
      this.$emit("addUserPanel", this.addUserPanelOpen);
    },
  },
  beforeDestroy() {
    this.getUsers();
  },
};
</script>

<style lang="scss" scoped>
.v-list-item {
  .v-list-item--link {
    .theme--light div {
      display: flex;
    }
  }
}

.col {
  padding: 0;
}
.left-row {
  justify-content: flex-start;
  .v-select {
    max-width: 250px;
  }
  .sector-type {
    margin-right: 42px;
  }
}
.right-row {
  justify-content: flex-end;
  .autocomplete {
    max-width: 300px;
  }
  .icon-button {
    border: 1px solid #e4e4e4;
    height: 40px;
    width: 40px;
    display: flex;
    outline: none;
    padding: 16px;
    align-items: center;
    user-select: none;
    margin-right: 16px;
    margin-left: 16px;
    border-radius: 8px;

    justify-content: center;
  }
}
.icon-button-active {
  background-color: #f4f5f7;
}

#usersView {
  //padding-bottom: 22px;
}
#settingsView,
#userGroupsView {
  margin-left: 24px;
  //padding-bottom: 22px;
}
#usersView,
#settingsView,
#userGroupsView {
  cursor: pointer;
}
.active-view {
  border-bottom: 2px solid black;
}
.active-view-font {
  font-weight: 600;
}
::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: 34px !important;
  max-height: 34px !important;
}
::v-deep .v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 5.5px;
}
::v-deep .v-icon.v-icon {
  font-size: 18px;
}
::v-deep .theme--light.v-icon {
  color: black;
}
::v-deep .v-text-field__slot input {
  font: revert !important;
}
.toggle-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
</style>
