<template>
  <div class="change-position-modal"
       v-click-outside="closePanel"
       @mouseup="mouseUp"
  >
    <div class="mega-line"></div>
    <vue-draggable-resizable
        id="vue-draggable"
        v-if="watermark.imgUrl"
        :key="updateMe"
        class="change-position-modal__img dragg"
        :x="draggableWatermark.posX"
        :y="draggableWatermark.posY"
        :w="draggableWatermark.width"
        :h="draggableWatermark.height"
        @resizing="onResize"
        @resizestop="onResizeStop"
        :lock-aspect-ratio="true"
        :onDrag="(x, y) => onDrag(x, y)"
    >
      <img
          v-if="watermark"
          v-bind:style="watermarkOpacity"
          :src="draggableWatermark.src"
          alt=""
      >
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import axios from "axios";
export default {
  name: "modalLogoPosition",
  components: {
    VueDraggableResizable
  },
  props: [
    "preview",
    "watermark",
    "companyId",
    "companyWatermark",
    "watermarkUpdated",
    "opacity",
    "watermarkChanged",
  ],
  data() {
    return {
      edges: [],
      threshold: 40,
      updateMe: 0,
      mainImg: "",
      hasSnapped: false,
      snappingArea: "",
      draggableWatermark: {
        src: this.watermark.imgUrl,
        posX: 0,
        posY: 0,
        width: 0,
        height: 0,
        snap: "top-left",
        opacity: 0,
      },
      canvasWidth: 1920,
      canvasHeight: 1080,
    };
  },
  created() {
    this.initDraggableWatermark()
  },
  mounted() {
    window.addEventListener('mouseup', this.mouseUp)
  },
  computed: {
    watermarkOpacity() {
      return {opacity: this.convertPercentage(this.opacity)}
    },
    isWideWatermark() {
      return this.draggableWatermark.width >= this.canvasWidth - this.threshold * 2;
    }
  },
  watch: {
    watermarkUpdated() {
      this.initDraggableWatermark()
      this.updateMe++;
    },
    opacity() {
      this.snapDuringDragging();
      this.$emit('watermarkChanged', true)
    },
  },
  methods: {
    convertPercentage(input) {
      return (input / 100).toFixed(2);
    },
    mouseUp() {
      if(!this.hasSnapped) {
        this.snapAfterDragging()
      }
    },
    onResize(x, y, width, height) {
      this.draggableWatermark.posX = x;
      this.draggableWatermark.posY = y;
      this.draggableWatermark.width = width;
      this.draggableWatermark.height = height;
      this.$emit('watermarkChanged', true)
    },
    onDrag(x, y) {
      this.hasSnapped = false
      this.draggableWatermark.posX = x;
      this.draggableWatermark.posY = y;
      this.snapDuringDragging();
      this.$emit('watermarkChanged', true)
      return false;
    },
    snapDuringDragging() {

      let tempX = Math.abs(this.draggableWatermark.posX);
      let tempY = Math.abs(this.draggableWatermark.posY);

      if(tempX <= this.canvasWidth / 2 && tempY <= this.canvasHeight / 2){
        tempX = Math.abs(this.canvasWidth / 2 - this.draggableWatermark.posX - this.draggableWatermark.width / 2);
        tempY = Math.abs(this.canvasHeight / 2 - this.draggableWatermark.posY - this.draggableWatermark.height / 2);
      }
      if(tempX <= this.canvasWidth / 2 && tempY >= this.canvasHeight / 2){
        tempX = Math.abs(this.canvasWidth / 2 - this.draggableWatermark.posX - this.draggableWatermark.width / 2);
        tempY = Math.abs(this.canvasHeight / 2 - this.draggableWatermark.posY - this.draggableWatermark.height / 2);
      }
      if(tempX >= this.canvasWidth / 2 && tempY <= this.canvasHeight / 2){
        tempX = Math.abs(this.canvasWidth / 2 - this.draggableWatermark.posX - this.draggableWatermark.width / 2);
        tempY = Math.abs(this.canvasHeight / 2 - this.draggableWatermark.posY - this.draggableWatermark.height / 2);
      }
      if(tempX >= this.canvasWidth / 2 && tempY >= this.canvasHeight / 2){
        tempX = Math.abs(this.canvasWidth / 2 - this.draggableWatermark.posX - this.draggableWatermark.width / 2);
        tempY = Math.abs(this.canvasHeight / 2 - this.draggableWatermark.posY - this.draggableWatermark.height / 2);
      }

      const lengthFromWatermark = [{
        name: "top",
        value: this.draggableWatermark.posY
      }, {
        name: "left",
        value: this.draggableWatermark.posX
      }, {
        name: "right",
        value: this.canvasWidth - (this.draggableWatermark.width + this.draggableWatermark.posX)
      }, {
        name: "bottom",
        value: this.canvasHeight - (this.draggableWatermark.height + this.draggableWatermark.posY)
      }, {
        name: "center",
        value: Math.abs(tempX + tempY)
      }];

      this.edges = lengthFromWatermark.sort((obj1, obj2) => obj1.value - obj2.value)

      if(this.isWideWatermark) {
        if((this.edges[0].name === 'right' && this.edges[1].name === 'left') || (this.edges[0].name === 'left' && this.edges[1].name === 'right')) {
          this.edges.splice(0, 2);
        }
      }
      const closestEdge = this.edges[0]

      if(closestEdge.value < this.threshold ) {
        this.snapAfterDragging()
        this.hasSnapped = true
      }
    },
    onResizeStop() {
      this.snapAfterDragging()
    },
    snapAfterDragging() {
      const closestEdge = this.edges[0]
      const secondClosestEdge = this.edges[1]
      const midWidthCanvas = this.canvasWidth / 2
      const midHeightCanvas = this.canvasHeight / 2
      const midWidthElement = this.draggableWatermark.width / 2
      const midHeightElement = this.draggableWatermark.height / 2

      if(closestEdge && closestEdge.name === "top") {
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'left') {
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = '';
        }
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'right') {
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = '';
        }

        if(secondClosestEdge.name === 'center' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.snap = 'top-center'
        }

        if(this.draggableWatermark.posX + midWidthElement <= midWidthCanvas + this.threshold &&
            this.draggableWatermark.posX + midWidthElement >= midWidthCanvas - this.threshold) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.snap = "top-center"
        } else {
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.snap = ""
        }

      } if(closestEdge && closestEdge.name === "left") {
        if(secondClosestEdge.value <  this.threshold && secondClosestEdge.name === 'top' && !this.isWideWatermark) {
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = '';
        }
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'bottom' && !this.isWideWatermark) {
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = '';
        }
        if(this.draggableWatermark.posY + midHeightElement <= midHeightCanvas + this.threshold &&
            this.draggableWatermark.posY + midHeightElement >= midHeightCanvas - this.threshold) {
          this.draggableWatermark.posY = midHeightCanvas - midHeightElement
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = "left-center"

        } else if (!this.isWideWatermark) {
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = ""

        } else if (secondClosestEdge.name === 'bottom' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.snap = 'bottom-center'

        } else if (secondClosestEdge.name === 'top' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.snap = 'top-center'
        }

      } if(closestEdge && closestEdge.name === "right") {
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'top' && !this.isWideWatermark) {
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = '';
        }
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'bottom' && !this.isWideWatermark) {
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = '';
        }

        if(this.draggableWatermark.posY + midHeightElement <= midHeightCanvas + this.threshold &&
            this.draggableWatermark.posY + midHeightElement >= midHeightCanvas - this.threshold) {
          this.draggableWatermark.posY = midHeightCanvas - midHeightElement
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = "right-center"

        } else if (!this.isWideWatermark) {
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = ""

        } else if (secondClosestEdge.name === 'bottom' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.snap = 'bottom-center'

        } else if (secondClosestEdge.name === 'top' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = 0;
          this.draggableWatermark.snap = 'top-center'
        }

      } if(closestEdge && closestEdge.name === "bottom") {

        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'left') {

          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.posX = 0;
          this.draggableWatermark.snap = '';
        }
        if(secondClosestEdge.value < this.threshold && secondClosestEdge.name === 'right') {
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.posX = this.canvasWidth - this.draggableWatermark.width;
          this.draggableWatermark.snap = '';
        }

        if(secondClosestEdge.name === 'center' && this.isWideWatermark) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.snap = 'bottom-center'
        }

        if(this.draggableWatermark.posX + midWidthElement <= midWidthCanvas + this.threshold &&
            this.draggableWatermark.posX + midWidthElement >= midWidthCanvas - this.threshold) {
          this.draggableWatermark.posX = midWidthCanvas - midWidthElement
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.snap = 'bottom-center'
        } else {
          this.draggableWatermark.posY = this.canvasHeight - this.draggableWatermark.height;
          this.draggableWatermark.snap = ""
        }

      } if(closestEdge && closestEdge.name === "center") {
        this.draggableWatermark.posX = midWidthCanvas - midWidthElement;
        this.draggableWatermark.posY = midHeightCanvas - midHeightElement;
        this.draggableWatermark.snap = '';
      }
    },
    initDraggableWatermark() {
      this.draggableWatermark.src = this.watermark.imgUrl;
      this.draggableWatermark.posX = this.watermark.positions.x;
      this.draggableWatermark.posY = this.watermark.positions.y;
      this.draggableWatermark.width = this.watermark.size.width;
      this.draggableWatermark.height = this.watermark.size.height;
      this.draggableWatermark.snap = this.watermark.positions.snap;
      this.draggableWatermark.opacity = this.watermark.opacity
    },

    async closePanel() {
      if(this.preview) {
        return
      }
      this.$emit('watermarkChanged', this.watermarkChanged)
      this.$emit('close-modal')
    },
    async saveWatermark() {
      if(!this.watermarkChanged) {
        console.log('no changes')
        return
      }
      const updatedWatermark = {
        companyId: this.companyId,
        image: this.draggableWatermark.src,
        name: this.watermark.name,
        imageSpecifics: {
          x: this.draggableWatermark.posX,
          y: this.draggableWatermark.posY,
          width: Math.round(this.draggableWatermark.width),
          height: Math.round(this.draggableWatermark.height),
          opacity: this.opacity,
          closestEdge: this.edges,
          snap: this.draggableWatermark.snap,
        }
      }
      this.$emit('updated', updatedWatermark)
      await axios.post("/api/company/add-logo", updatedWatermark)
      this.watermarkChanged = false
    }
  }
}
</script>

<style lang="scss" scoped>
.change-position-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.55);
  width: 1920px;
  height: 1080px;
  background-image: url("https://images.adsttc.com/media/images/5e68/48ed/b357/658e/fb00/0441/large_jpg/AM1506.jpg?1583892706");
  background-size: cover;
  z-index: 3;
  overflow: hidden;
}
.change-position-modal__img {
  width: fit-content ;
  img{
    width: 100%;
  }
}
.change-position-modal__img img {
  height: 100%;
  position: relative;
}
.mega-line {
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: aquamarine;
  position: absolute;
  transform: translateX(50%);
  opacity: 0.5;
}

::v-deep .dragg {
  & .vdr {
    touch-action:none;
    border:1px dashed #000;
  }
  & .handle, & .vdr {
    position:absolute;
    box-sizing:border-box;
  }
  & .handle {
    position:absolute;
    box-sizing:border-box;
  }
  & .handle {
    width:10px;
    height:10px;
    background:#eee;
    border:1px solid #333
  }
  & .handle-tl {
    top:-10px;
    left:-10px;
    cursor:nw-resize
  }
  & .handle-tm {
    top:-10px;
    left:50%;
    margin-left:-5px;
    cursor:n-resize
  }
  & .handle-tr {
    top:-10px;
    right:-10px;
    cursor:ne-resize
  }
  & .handle-ml {
    left:-10px;
    cursor:w-resize
  }
  & .handle-ml {
    top:50%;
    margin-top:-5px
  }
  & .handle-mr {
    top:50%;
    margin-top:-5px
  }
  & .handle-mr {
    right:-10px;
    cursor:e-resize
  }
  & .handle-bl {
    bottom:-10px;
    left:-10px;
    cursor:sw-resize
  }
  & .handle-bm {
    bottom:-10px;
    left:50%;
    margin-left:-5px;
    cursor:s-resize}
  & .handle-br {
    bottom:-10px;
    right:-10px;
    cursor:se-resize
  }
  @media only screen and (max-width:768px) {
    [class*=handle-]:before {
      content:"";
      left:-10px;
      right:-10px;
      bottom:-10px;
      top:-10px;
      position:absolute
    }
  }
}
</style>