var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-panel-overlay",
    attrs: {
      "id": "user-overlay"
    },
    on: {
      "click": _vm.closePanel
    }
  }, [_vm.userLoaded ? _c('div', {
    staticClass: "user-panel"
  }, [_c('div', {
    staticClass: "user-panel-header",
    attrs: {
      "id": "userpanel-header"
    }
  }, [_c('v-row', {
    staticStyle: {
      "justify-content": "space-between",
      "margin-right": "30px"
    }
  }, [_c('div', {
    staticClass: "user-status-wrapper"
  }, [_c('p', {
    staticClass: "statusLabel content-bold",
    class: {
      'invited-label ': _vm.editUser.userStatus === 'Invited',
      'active-label ': _vm.editUser.userStatus === 'Active',
      'inactive-label ': _vm.editUser.userStatus === 'Inactive'
    },
    attrs: {
      "id": 'statusLabel' + _vm.editUser._id
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.editUser.userStatus)) + " ")]), _vm.editUser.userStatus === 'Invited' && !_vm.invitationSent && _vm.allowedToEdit ? _c('p', {
    staticClass: "link-small re-invite-button",
    on: {
      "click": _vm.inviteAgain
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ResendInvitation")) + " ")]) : _vm._e(), _vm.editUser.userStatus !== 'Invited' && !_vm.invitationSent && _vm.allowedToEdit ? _c('p', {
    staticClass: "link-small re-invite-button",
    on: {
      "click": _vm.requestPasswordReset
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ResetPassword")) + " ")]) : _vm._e(), _vm.invitationSent ? _c('p', {
    staticClass: "content-small re-invite-button-disabled"
  }, [_vm._v(" " + _vm._s(_vm.$t("RequestSent")) + " ")]) : _vm._e()]), _vm.allowedToEdit ? _c('EditButton', {
    on: {
      "clicked": function ($event) {
        return _vm.editUserButton(_vm.editUser, false, false);
      }
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "8px"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.editUser.name))])])], 1), _c('div', {
    staticClass: "user-panel-body",
    attrs: {
      "id": "user-body"
    }
  }, [_c('div', {
    staticClass: "main-info-section"
  }, [_c('h5', {
    staticClass: "main-info-title"
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _vm.editUser.email ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("e-mail")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.editUser.email))])]) : _vm._e(), _vm.editUser.phoneNumber ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PhoneNum")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.editUser.phoneNumber))])]) : _vm._e(), _vm.editUser.personalCode ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PersonalCode")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.editUser.personalCode))])]) : _vm._e(), _vm.editUser.types && !Object.values(_vm.editUser.types).every(function (x) {
    return x.value === false;
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Role")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatUserObject(_vm.editUser.types)))])]) : _vm._e(), _vm.editUser.languages ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Languages")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatUserObject(_vm.editUser.languages)))])]) : _vm._e(), _vm.editUser.groups ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Groups")))]), _c('div', {
    staticClass: "field-value"
  }, [_vm.editUser.groups.length ? _c('p', [_vm._v(" " + _vm._s(_vm.formatGroupField(_vm.editUser)) + " ")]) : _vm._e(), !_vm.editUser.groups.length ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("UnAllocated")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.editUser.groups.length && _vm.editUser.groups.filter(function (x) {
    return x.userDefault;
  }).length ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("DefaultGroup")))]), _c('div', {
    staticClass: "field-value"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formatDefaultGroup(_vm.editUser.groups)) + " ")])])]) : _vm._e(), _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AccessLevel")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.formatUserRole(_vm.editUser)))])]), _vm.editUser.groups.length && _vm.editUser.isGroupAdmin ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AdminInGroups")))]), _c('div', {
    staticClass: "field-value"
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.formatAdminInGroups(_vm.editUser.groups)) + " ")])])]) : _vm._e(), _vm.editUser.personalPage ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PersonalWWWPage")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.editUser.personalPage))])]) : _vm._e(), _vm.editUser.socials && !Object.values(_vm.editUser.socials).every(function (x) {
    return x === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name content"
  }, [_vm._v(_vm._s(_vm.$t("SocialMedia")))]), _c('div', {
    staticClass: "social-images",
    staticStyle: {
      "display": "flex",
      "gap": "12px"
    }
  }, [_vm.editUser.socials.facebook ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/facebook.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.editUser.socials.facebook);
      }
    }
  }) : _vm._e(), _vm.editUser.socials.instagram ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/instagram.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.editUser.socials.instagram);
      }
    }
  }) : _vm._e(), _vm.editUser.socials.linkedIn ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/linkedIn.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.editUser.socials.linkedIn);
      }
    }
  }) : _vm._e(), _vm.editUser.socials.tiktok ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/tiktok.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.editUser.socials.tiktok);
      }
    }
  }) : _vm._e(), _vm.editUser.socials.youtube ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "alt": "",
      "src": require("../../assets/images/youtube.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.openLink(_vm.editUser.socials.youtube);
      }
    }
  }) : _vm._e()])]) : _vm._e(), _vm.editUser.occupation && !Object.values(_vm.editUser.occupation).every(function (x) {
    return x === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Occupation")))]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.editUser.occupation).filter(function (x) {
    return x !== '';
  }), function (occupation) {
    return _c('div', {
      key: occupation,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "flex-wrap": "nowrap"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "244px"
      }
    }, [_vm._v(_vm._s(occupation))])]);
  }), 0)]) : _vm._e(), _vm.editUser.profilePicture && _vm.editUser.profilePicture.length && _vm.editUser.profilePicture[0].name ? _c('v-row', {
    staticClass: "user-field-row",
    attrs: {
      "id": "lightgallery-user"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Image")))]), _c('img', {
    staticClass: "profile-picture",
    attrs: {
      "src": _vm.editUser.profilePicture[0].name,
      "alt": ""
    },
    on: {
      "click": _vm.openImageGallery
    }
  })]) : _vm._e(), _vm.editUser.about && !Object.values(_vm.editUser.about).every(function (x) {
    return x === '';
  }) ? _c('v-row', {
    staticClass: "user-field-row"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Description")))]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.editUser.about).filter(function (x) {
    return x !== '';
  }), function (desc) {
    return _c('div', {
      key: desc,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "flex-wrap": "nowrap"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "244px"
      }
    }, [_vm._v(_vm._s(desc))])]);
  }), 0)]) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "height": "1px",
      "background": "#e6e8ec",
      "margin-top": "24px"
    }
  }), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('MyFeedback'),
      "separator-line": true,
      "identifier": 'feedback',
      "show-bot-divider": true,
      "parent-name": 'brokerSettings',
      "buttons-open-accordion": true,
      "padding-top": '24px',
      "padding-bottom": '24px',
      "button-name": _vm.$t('Edit'),
      "button-icon": 'edit_16_16.svg'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.editUserButton(_vm.editUser, true, false);
      }
    }
  }, [!_vm.editUser.clients ? _c('div', {
    staticClass: "content",
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(this.$t("NoFeedback")) + " ")]) : _vm._e(), _vm.editUser.clients && _vm.feedback.length ? _c('v-row', [_c('div', {
    staticClass: "description content",
    staticStyle: {
      "margin-top": "0px",
      "padding-bottom": "24px"
    }
  }, [_c('v-row', {
    staticClass: "description-item",
    staticStyle: {
      "margin-top": "0px",
      "padding-top": "0px"
    }
  }, [!_vm.showFullText ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.feedback,
      expression: "feedback"
    }],
    staticClass: "description-item1 content",
    staticStyle: {},
    attrs: {
      "outlined": "",
      "rows": "3",
      "id": "text-area",
      "readonly": ""
    },
    domProps: {
      "value": _vm.feedback
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.feedback = $event.target.value;
      }
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "show-more-button",
    class: {
      hidden: _vm.showFullText
    }
  }, [_c('button', {
    staticStyle: {
      "text-decoration-line": "underline"
    },
    attrs: {
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showText();
      }
    }
  }, [_vm.editUser.clients && (_vm.feedback.length > 456 || this.rowCount > 5) ? _c('p', {
    staticClass: "link"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowMore")) + " ")]) : _vm._e()])]), _c('v-row', {
    staticStyle: {
      "margin-top": "0px",
      "width": "477px"
    }
  }, [_vm.showFullText ? _c('v-textarea', {
    staticClass: "description-textarea content",
    attrs: {
      "hide-details": "",
      "name": "input-7-1",
      "auto-grow": "",
      "readonly": "",
      "color": "black"
    },
    model: {
      value: _vm.feedback,
      callback: function ($$v) {
        _vm.feedback = $$v;
      },
      expression: "feedback"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "show-less-button",
    class: {
      hidden: !_vm.showFullText
    }
  }, [_c('button', {
    staticStyle: {
      "padding-left": "0px",
      "text-decoration-line": "underline"
    },
    attrs: {
      "for": "expanded",
      "id": "textButton"
    },
    on: {
      "click": function ($event) {
        return _vm.showTextLess();
      }
    }
  }, [_c('p', {
    staticClass: "link"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowLess")) + " ")])])])], 1)]) : _vm._e()], 1), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('Achievements'),
      "separator-line": true,
      "identifier": 'achievements',
      "parent-name": 'brokerSettings',
      "buttons-open-accordion": true,
      "show-bot-divider": false,
      "padding-top": '24px',
      "padding-bottom": '24px',
      "button-name": _vm.$t('Edit'),
      "button-icon": 'edit_16_16.svg'
    },
    on: {
      "buttonPressed": function ($event) {
        return _vm.editUserButton(_vm.editUser, false, true);
      }
    }
  }, [!_vm.editUser.achievements ? _c('div', {
    staticClass: "content",
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(this.$t("NoAchievements")) + " ")]) : _vm._e(), _vm.editUser.achievements ? _c('div', {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, _vm._l(Object.values(_vm.editUser.achievements).filter(function (x) {
    return x !== '';
  }), function (achievement) {
    return _c('div', {
      key: achievement,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "flex-wrap": "wrap",
        "word-break": "break-word"
      }
    }, [_c('p', {
      staticStyle: {
        "max-width": "477px"
      }
    }, [_vm._v(_vm._s(achievement))])]);
  }), 0)]) : _vm._e()]), _c('v-row', {
    staticStyle: {
      "height": "1px",
      "background": "#e6e8ec"
    }
  }), _c('BaseAccordion', {
    attrs: {
      "title": _vm.$t('ListingLastImage'),
      "separator-line": true,
      "identifier": 'listingLastImage',
      "parent-name": 'brokerSettings',
      "button-opens-accordion": true,
      "padding-top": '24px',
      "padding-bottom": '24px'
    }
  }, [_c('div', {
    staticStyle: {
      "max-width": "251px"
    }
  }, [_c('ImageUpload', {
    attrs: {
      "maxAmountOfImages": 1,
      "can-delete": true,
      "images": _vm.brokerLastImage,
      "label": _vm.$t('UploadImage'),
      "compress": true,
      "drag-and-drop": true
    },
    on: {
      "change": _vm.changeImage
    }
  })], 1)])], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }