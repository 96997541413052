var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "watermark-upload-row"
  }, [_c('v-col', {
    staticStyle: {
      "max-width": "120px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "height": "fit-content",
      "gap": "16px"
    }
  }, [_c('input', {
    ref: "watermarkInput",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "type": "file",
      "id": "file-upload-input",
      "hidden": ""
    },
    on: {
      "change": _vm.uploadWatermark
    }
  }), _c('div', {
    staticStyle: {
      "padding": "4px 8px",
      "cursor": "pointer",
      "border": "1px dashed #c3c4c6",
      "height": "24px",
      "gap": "6px",
      "border-radius": "8px",
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": function () {
        return _vm.$refs.watermarkInput.click();
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "src": require('@/assets/images/image.svg'),
      "alt": ""
    }
  }), _c('label', {
    staticStyle: {
      "width": "100%",
      "cursor": "pointer",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Upload")))])]), _vm.watermark.name ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.watermark.name) + " ")]) : _vm._e(), _vm.watermarkUploaded ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../../assets/images/action-delete.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.deleteWatermark
    }
  }) : _vm._e()])])], 1), _c('v-row', {
    staticClass: "watermark-preview-row"
  }, [_c('v-col', {
    staticClass: "watermark-opacity-column"
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Opacity'),
      "icon-right": 'percentage.svg',
      "type": "number"
    },
    model: {
      value: _vm.watermark.opacity,
      callback: function ($$v) {
        _vm.$set(_vm.watermark, "opacity", $$v);
      },
      expression: "watermark.opacity"
    }
  }), _c('submit-button', {
    staticClass: "save-watermark-button",
    on: {
      "clicked": _vm.saveWatermark
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "preview-container",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.openWatermarkPositionModal
    }
  }, [_c('p', {
    staticClass: "preview-container_text"
  }, [_vm._v(_vm._s(_vm.$t("Preview")))]), _c('LogoPositionModal', {
    ref: "logoPositionModal",
    class: _vm.switchClass,
    attrs: {
      "watermarkUpdated": _vm.watermarkUpdater,
      "watermark": _vm.watermark,
      "preview": _vm.preview,
      "companyId": _vm.companyId,
      "opacity": _vm.watermark.opacity,
      "companyWatermark": _vm.companyWatermark,
      "watermarkChanged": _vm.watermarkChanged
    },
    on: {
      "close-modal": _vm.closeWatermarkPositionModal,
      "updated": _vm.updateWatermark,
      "watermarkChanged": _vm.changeStatusUpdater
    }
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }