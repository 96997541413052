var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "change-position-modal",
    on: {
      "mouseup": _vm.mouseUp
    }
  }, [_c('div', {
    staticClass: "mega-line"
  }), _vm.watermark.imgUrl ? _c('vue-draggable-resizable', {
    key: _vm.updateMe,
    staticClass: "change-position-modal__img dragg",
    attrs: {
      "id": "vue-draggable",
      "x": _vm.draggableWatermark.posX,
      "y": _vm.draggableWatermark.posY,
      "w": _vm.draggableWatermark.width,
      "h": _vm.draggableWatermark.height,
      "lock-aspect-ratio": true,
      "onDrag": function (x, y) {
        return _vm.onDrag(x, y);
      }
    },
    on: {
      "resizing": _vm.onResize,
      "resizestop": _vm.onResizeStop
    }
  }, [_vm.watermark ? _c('img', {
    style: _vm.watermarkOpacity,
    attrs: {
      "src": _vm.draggableWatermark.src,
      "alt": ""
    }
  }) : _vm._e()]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }