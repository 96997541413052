<template>
  <div class="content">
    <UsersContent
      :search="search"
      :updated="userUpdated"
      v-if="activeView === 'Users'"
      :active-users-only="activeUsersToggle"
    />
    <AdminSettingsContent v-if="activeView === 'Settings'" />
    <UserGroupsContent v-if="activeView === 'UserGroups'" />
    <AddUserSidepanel
      @userAdded="updateUserList"
      @closed="closePanel"
      v-if="addUserPanelOpen"
    />
  </div>
</template>
<script>
import UsersContent from "@/components/Admin/UsersContent.vue";
import AdminSettingsContent from "@/components/Admin/SettingsContent.vue";
import UserGroupsContent from "@/components/Admin/UserGroupsContent";
import AddUserSidepanel from "@/components/Admin/AddUserSidepanel";
//import axios from "axios";
//import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    AddUserSidepanel,
    UserGroupsContent,
    UsersContent,
    AdminSettingsContent,
  },
  //??
  props: ["activeView", "search", "addUserPanelOpen", "activeUsersToggle"],
  data() {
    return {
      showDate: null,
      userUpdated: 0,
    };
  },
  created() {
  },
  mounted() {    
  },
  watch: {},
  methods: {
    closePanel() {
      this.$emit("userSidepanelClosed");
    },
    updateUserList() {
      this.userUpdated++;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  background-color: #f4f5f7;
  flex-direction: row;
  flex-grow: 1;
  /*justify-content: center;
  align-items: center;*/
  padding: 24px 64px 72px 104px;
}
.calendar-wrap {
  max-width: 1650px;
  width: 1650px;
  height: 100%;
}
.header-row {
  max-height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
::v-deep .cv-header-day,
.cv-header-days,
.cv-item,
.cv-week,
.cv-weeks {
  border: none;
  background-color: white;
}
::v-deep .cv-weekdays {
  background-color: white;
}
::v-deep .cv-weeks {
  border: none;
}
::v-deep .cv-header-days {
  border: none;
}
::v-deep .cv-week {
  background-color: white;
  padding-left: 8px;
  padding-right: 8px;
}
::v-deep .cv-day {
  border: none;
  margin: 8px;
  border-radius: 8px;
  background-color: #f4f5f7 !important;
}
::v-deep .cv-day-number {
  padding: 1em !important;
  font-weight: bold;
}
::v-deep .cv-day.outsideOfMonth {
  background-color: rgb(255 255 255) !important;
  border: 1px solid rgb(244, 245, 247);
}
</style>
