<template>
  <div id="admin-content" class="main-wrapper">
    <div
        v-if="filteredUsers && filteredUsers.length < 1"
        style="padding-top: 24px"
    >
      <h3>{{ $t("NoMatchesFound") }}</h3>
    </div>
    <div v-if="!loaded">
      <div
          style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
          v-if="!loaded"
          class="loader"
      ></div>
    </div>
    <Table
        v-if="filteredUsers && filteredUsers.length >= 1 && loaded"
        :fields="tableFields"
        :dataset="filteredUsers"
        :add-gap="true"
        :admin-view="true"
        :listing-view="true"
        :update-key="updatedUser"
        :admin-mode="true"
        :sort-again="sortUpdater"
        @totalListingCounter="countTotalActiveListings"
        @userClicked="openUser"
    />

    <UserSidepanel
        v-if="userPanelOpen"
        @overlayClicked="closeUserPanel"
        @editUser="editUser"
        :key="userUpdated"
        :userId="clickedUser._id"
    />
    <AddUserSidepanel
        :edit="true"
        :editable-user="userToEdit"
        @closed="closeEditUserPanel"
        v-if="editUserPanelOpen"
        :feedback-only="showFeedbackField"
        :achievements-only="showAchievementsField"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Table from "@/components/common/Table";
import UserSidepanel from "@/components/Admin/UserSidepanel";
import AddUserSidepanel from "@/components/Admin/AddUserSidepanel";

export default {
  name: "UsersContent",
  components: {
    Table,
    UserSidepanel,
    AddUserSidepanel,
  },
  props: ["search", "updated", "activeUsersOnly"],
  data() {
    return {
      loaded: false,
      clickedUser: null,
      userPanelOpen: false,
      editUserPanelOpen: false,
      showFeedbackField: false,
      showAchievementsField: false,
      userToEdit: null,
      listings: null,
      tableCloser: 0,
      sortUpdater: 0,
      updatedUser: 0,
      userUpdated: 0,
      tableFields: [
        {
          fieldValue: this.$t("FirstName"),
          jsonValue: "firstname",
          style: "min-width:120px; max-width:120px; height:60px",
        },
        {
          fieldValue: this.$t("LastName"),
          jsonValue: "lastname",
          style: "min-width: 120px; max-width: 120px; height:60px;",
        },
        {
          fieldValue: this.$t("Role"),
          jsonValue: "types",
          style: "min-width: 120px; max-width: 160px; height:60px;",
        },
        {
          fieldValue: this.$t("AccessLevel"),
          jsonValue: "accessLevel",
          style: "min-width: 120px; max-width: 120px; height:60px;",
        },
        {
          fieldValue: this.$t("DefaultGroup"),
          jsonValue: "group",
          style: "min-width: 168px; max-width: 168px; height:60px",
        },
        {
          fieldValue: this.$t("GroupsCount"),
          jsonValue: "groups",
          style: "min-width: 120px; max-width: 120px; height:60px",
        },
        {
          fieldValue: this.$t("Status"),
          jsonValue: "userStatus",
          style: "min-width: 120px; max-width: 120px; height:60px",
        },
        {
          fieldValue: this.$t("LastLogin"),
          jsonValue: "LastLogin",
          style: "min-width: 152px; max-width: 120px; height:60px",
        },
        {
          fieldValue: this.$t("Listings"),
          jsonValue: "listings",
          style: "min-width: 120x; max-width: 120px; height:60px",
        },
        {
          fieldValue: this.$t("ActiveListings"),
          jsonValue: "activeListings",
          style: "min-width: 128px; max-width: 128px; height:60px",
        },
        {
          fieldValue: "Kv",
          jsonValue: "kvActive",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
        {
          fieldValue: "City24",
          jsonValue: "city24Active",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
        {
          fieldValue: "Kv24",
          jsonValue: "kv24Active",
          style: "min-width: 96px; max-width: 96px; height:60px",
        },
        {
          fieldValue: "www",
          jsonValue: "wwwActive",
          style: "min-width: 96px; max-width: 96px; height:60px;",
        },
      ],
      originalArr: null,
      filteredUsers: []
    };
  },
  created() {
    this.setupUsers();
    //this.countTotalActiveListings();
  },
  mounted() {
  },
  watch: {
    async activeUsersOnly(value) {
      if (value) {
        let onlyActiveUsers = this.colleagues.filter((user) => user.userStatus === 'Active')
        this.filteredUsers = onlyActiveUsers;
      } else {
        this.filteredUsers = this.colleagues;
      }
      this.updatedUser++;
    },
    usersUpdaterKey() {
      this.updatedUser++;
    },
    updated: async function () {
      await this.setupUsers();
      this.updatedUser++;
    },
    search() {
      this.originalArr = [...this.colleagues];
      let searchValue = this.search.toLowerCase().trim();
      let searchWords = searchValue.split(/\s+/); // Split by one or more spaces

      if (this.activeUsersOnly) {
          this.originalArr = this.originalArr.filter((user) => user.userStatus === 'Active');
      }

      let tempUserNames = this.originalArr;

      if (searchValue !== "" && searchValue) {
          tempUserNames = tempUserNames.filter(item => {
              return searchWords.every(word => {
                  return (
                      item.firstname.toLowerCase().includes(word) ||
                      item.lastname.toLowerCase().includes(word)
                  );
              }) ||
              item.userStatus.toLowerCase().indexOf(searchValue) > -1 ||
              item.listings.toString().indexOf(searchValue) > -1;
          });

          if (tempUserNames.length) {
              this.filteredUsers = tempUserNames;
              this.updatedUser++;
              this.sortUpdater++;
          } else {
              this.filteredUsers = [];
              this.sortUpdater++;
              this.updatedUser++;
          }
      } else if (searchValue === "" || searchValue == null) {
          this.filteredUsers = this.originalArr;
          this.sortUpdater++;
          this.updatedUser++;
      }
    },
    filteredUsers: {
      async handler() {
        this.updatedUser++;
        this.sortUpdater++;
      },
      deep: false,
    },
  },
  computed: {
    ...mapGetters(["user", "users", "usersUpdaterKey", "groups"]),
    colleagues() {
      if (this.user.access.company.isAdmin) {
        return this.users;
      } else {
        return this.users.filter(user =>
            Object.keys(user.access.groups)
                .some((groupId) => this.user.access.groups[groupId]?.isAdmin)
        );
      }
    }
  },
  methods: {
    editUser(user, feedbackField, achievementsField) {
      this.userToEdit = user;
      this.showFeedbackField = feedbackField;
      this.showAchievementsField = achievementsField;
      this.editUserPanelOpen = true;
    },
    async closeEditUserPanel(updating) {
      this.showAchievementsField = false;
      this.showFeedbackField = false;
      this.userToEdit = null;
      this.editUserPanelOpen = false;
      if (updating) {
        await this.setupUsers();
        this.userUpdated++;
        this.sortUpdater++;
      }
    },
    openUser(user) {
      if (this.user.access.accessLevel === "company_admin" || this.user.access.accessLevel === "group_admin") {
        this.clickedUser = user;
        this.userPanelOpen = true;
      }
    },
    closeUserPanel() {
      this.clickedUser = null;
      this.userPanelOpen = false;
    },
    async setupUsers() {
      await this.getUsers();
      await this.getGroups();
      this.users.forEach((user) => {
        let defaultGroup = user.groups.find((group) => group.userDefault)
        user["group"] = defaultGroup || user.groups[0];
      });
      if (this.activeUsersOnly) {
        let onlyActiveUsers = this.colleagues.filter((user) => user.userStatus === 'Active')
        this.filteredUsers = onlyActiveUsers
      }
      this.loaded = true;
      this.updatedUser++;

    },
    ...mapActions(["getUsers", "getGroups"]),
    async countTotalActiveListings(saveBody) {
      let admin = saveBody.admin;
      admin.activeListingsTotal =
          parseInt(admin.kvTotal) +
          parseInt(admin.kv24Total) +
          parseInt(admin.city24Total) +
          parseInt(admin.wwwTotal);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  background-color: #f4f5f7;
  flex-wrap: nowrap;
  flex-direction: column;
}

.col {
  padding: 0;
}

.column-width {
  min-width: 208px;
  max-width: 208px;
}

.customer-row {
  margin-bottom: 8px;
  background-color: transparent;
}

.object-row {
  background: #ffff;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  margin-top: 16px;
}

.search-button {
  width: 260px;
}

.columns-wrapper {
  gap: 24px;
  margin-top: 28px;
  display: none;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  margin-top: 5px;
}

.task-name {
  margin-left: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}

.task-date {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
  margin-left: 8px;
}

.task-date-row {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: left;
  color: #000000;
  margin-top: -22px;
  margin-left: 34px;
}

.relatedCustomer-name {
  font-family: Inter, "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-left: 8px;
  width: calc(80%);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.parentName {
  font-family: Inter, "sans-serif";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-left: 8px;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-desc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* black */
  color: #000000;
  margin-top: 7px;
}

.gray-text {
  color: #75787a;
  font-size: 14px !important;
}

.search-row {
  width: 20px;
  height: 20px;
}

.images {
  display: flex;
  flex-direction: row;
  //align-items: flex-start;
  padding: 4px;
  position: static;
  width: 24px;
  height: 24px;
  background: #f4f5f7;
  border-radius: 100px;
  cursor: pointer;
}

.no-active {
  max-height: 200px;
  overflow: scroll;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.first-component {
  background: #ffffff;
  height: 56px;
  width: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px 0 0 8px;
}

.tasks-row {
  margin-bottom: -8px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: static;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 0px;
  margin-top: 8px;
  margin-left: 8px;
  background: #f4f5f7;
  border-radius: 100%;
}

.checkbox-outside {
  background: #f4f5f7;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.second-component {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  min-width: 440px;
  height: 56px;
  left: 57px;
  top: 0px;
  margin: 0px 1px;
}

.third-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;

  height: 56px;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */

  margin: 0px 1px;
}

.fourth-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  min-width: 25%;
  height: 56px;
  min-width: 160px;
  background: #ffffff;
  margin: 0px 1px;
}

.fifth-component {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  height: 56px;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */

  margin: 0px 1px;
}

.sixth-component {
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;

  height: 56px;
  border-radius: 0 8px 8px 0;
  width: 160px;
  /* white */

  background: #ffffff;

  /* Inside Auto Layout */
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.filter-image {
  height: 13.728036880493164px;
  width: 12.532658576965332px;
  left: 1.733642578125px;
  top: 1.3330078125px;
  border-radius: 0px;
  margin-top: 1px;
}

.menu-item-person {
  cursor: pointer;
  background-color: white;
  margin-top: 0 !important;
  padding: 12px;

  &:hover {
    background-color: #e6e8ec;
  }

  p {
    font-size: 14px;
    margin-bottom: 0 !important;
  }
}

.white-background {
  background-color: #ffffff;
}

#listing-header,
#listing-content {
  transition: width, margin-left 300ms ease-in-out;
}

.slide-animation {
  margin-left: 256px !important;
}

.slide-animation-close {
  margin-left: 0 !important;
}

.ghost-card {
  background-color: #c1c1c1 !important;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
}

.scrollable {
  min-height: 48px;
}

.dot {
  font-weight: 900;
}
</style>
