var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-wrapper",
    attrs: {
      "id": "admin-content"
    }
  }, [_vm.filteredUsers && _vm.filteredUsers.length < 1 ? _c('div', {
    staticStyle: {
      "padding-top": "24px"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("NoMatchesFound")))])]) : _vm._e(), !_vm.loaded ? _c('div', [!_vm.loaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "margin-top": "24px",
      "position": "absolute",
      "top": "30%",
      "left": "50%"
    }
  }) : _vm._e()]) : _vm._e(), _vm.filteredUsers && _vm.filteredUsers.length >= 1 && _vm.loaded ? _c('Table', {
    attrs: {
      "fields": _vm.tableFields,
      "dataset": _vm.filteredUsers,
      "add-gap": true,
      "admin-view": true,
      "listing-view": true,
      "update-key": _vm.updatedUser,
      "admin-mode": true,
      "sort-again": _vm.sortUpdater
    },
    on: {
      "totalListingCounter": _vm.countTotalActiveListings,
      "userClicked": _vm.openUser
    }
  }) : _vm._e(), _vm.userPanelOpen ? _c('UserSidepanel', {
    key: _vm.userUpdated,
    attrs: {
      "userId": _vm.clickedUser._id
    },
    on: {
      "overlayClicked": _vm.closeUserPanel,
      "editUser": _vm.editUser
    }
  }) : _vm._e(), _vm.editUserPanelOpen ? _c('AddUserSidepanel', {
    attrs: {
      "edit": true,
      "editable-user": _vm.userToEdit,
      "feedback-only": _vm.showFeedbackField,
      "achievements-only": _vm.showAchievementsField
    },
    on: {
      "closed": _vm.closeEditUserPanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }