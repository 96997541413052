<template>
  <div @click="closePanel" class="user-panel-overlay" id="user-overlay">
    <div v-if="userLoaded" class="user-panel">
      <div class="user-panel-header" id="userpanel-header">
        <v-row style="justify-content: space-between; margin-right: 30px">
          <div class="user-status-wrapper">
            <p
                :id="'statusLabel' + editUser._id"
                class="statusLabel content-bold"
                v-bind:class="{
                'invited-label ': editUser.userStatus === 'Invited',
                'active-label ': editUser.userStatus === 'Active',
                'inactive-label ': editUser.userStatus === 'Inactive',
              }"
            >
              {{ $t(editUser.userStatus) }}
            </p>
            <p
                @click="inviteAgain"
                v-if="editUser.userStatus === 'Invited' && !invitationSent && allowedToEdit"
                class="link-small re-invite-button"
            >
              {{ $t("ResendInvitation") }}
            </p>
            <p
                @click="requestPasswordReset"
                v-if="editUser.userStatus !== 'Invited' && !invitationSent && allowedToEdit"
                class="link-small re-invite-button"
            >
              {{ $t("ResetPassword") }}
            </p>
            <p
                v-if="invitationSent"
                class="content-small re-invite-button-disabled"
            >
              {{ $t("RequestSent") }}
            </p>
          </div>
          <EditButton @clicked="editUserButton(editUser, false, false)" v-if="allowedToEdit"/>
        </v-row>
        <v-row style="margin-top: 8px">
          <h4>{{ editUser.name }}</h4>
        </v-row>
      </div>
      <div class="user-panel-body" id="user-body">
        <div class="main-info-section">
          <h5 class="main-info-title">{{ $t("Subblock.GeneralData") }}</h5>
          <v-row v-if="editUser.email" class="user-field-row">
            <p class="field-name">{{ $t("e-mail") }}</p>
            <p class="field-value">{{ editUser.email }}</p>
          </v-row>
          <v-row v-if="editUser.phoneNumber" class="user-field-row">
            <p class="field-name">{{ $t("PhoneNum") }}</p>
            <p class="field-value">{{ editUser.phoneNumber }}</p>
          </v-row>
          <v-row v-if="editUser.personalCode" class="user-field-row">
            <p class="field-name">{{ $t("PersonalCode") }}</p>
            <p class="field-value">{{ editUser.personalCode }}</p>
          </v-row>
          <v-row v-if="editUser.types && !Object.values(editUser.types).every((x) => x.value === false)" class="user-field-row">
            <p class="field-name">{{ $t("Role") }}</p>
            <p class="field-value">{{ formatUserObject(editUser.types) }}</p>
          </v-row>
          <v-row v-if="editUser.languages" class="user-field-row">
            <p class="field-name">{{ $t("Languages") }}</p>
            <p class="field-value">{{ formatUserObject(editUser.languages) }}</p>
          </v-row>
          <v-row v-if="editUser.groups" class="user-field-row">
            <p class="field-name">{{ $t("Groups") }}</p>
            <div class="field-value">
              <p v-if="editUser.groups.length">
                {{ formatGroupField(editUser) }}
              </p>
              <p v-if="!editUser.groups.length">
                {{ $t("UnAllocated") }}
              </p>
            </div>
          </v-row>
          <v-row v-if="editUser.groups.length && editUser.groups.filter((x) => x.userDefault).length" class="user-field-row">
            <p class="field-name">{{ $t("DefaultGroup") }}</p>
            <div class="field-value">
              <p>
                {{ formatDefaultGroup(editUser.groups) }}
              </p>
            </div>
          </v-row>
           <v-row class="user-field-row">
            <p class="field-name">{{ $t("AccessLevel") }}</p>
            <p class="field-value">{{ formatUserRole(editUser) }}</p>
          </v-row>
           <v-row v-if="editUser.groups.length && editUser.isGroupAdmin" class="user-field-row">
            <p class="field-name">{{ $t("AdminInGroups") }}</p>
            <div class="field-value">
              <p>
                {{ formatAdminInGroups(editUser.groups) }}
              </p>
            </div>
          </v-row>
          <v-row v-if="editUser.personalPage" class="user-field-row">
            <p class="field-name">{{ $t("PersonalWWWPage") }}</p>
            <p class="field-value">{{ editUser.personalPage }}</p>
          </v-row>
          <v-row
            v-if="
              editUser.socials && !Object.values(editUser.socials).every((x) => x === '')
            "
            class="user-field-row"
          >
            <p class="field-name content">{{ $t("SocialMedia") }}</p>
            <div class="social-images" style="display: flex; gap: 12px">
              <img
                v-if="editUser.socials.facebook"
                @click="openLink(editUser.socials.facebook)"
                style="cursor: pointer"
                alt=""
                src="../../assets/images/facebook.svg"
              />
              <img
                v-if="editUser.socials.instagram"
                @click="openLink(editUser.socials.instagram)"
                style="cursor: pointer"
                alt=""
                src="../../assets/images/instagram.svg"
              />
              <img
                v-if="editUser.socials.linkedIn"
                @click="openLink(editUser.socials.linkedIn)"
                style="cursor: pointer"
                alt=""
                src="../../assets/images/linkedIn.svg"
              />
              <img
                v-if="editUser.socials.tiktok"
                @click="openLink(editUser.socials.tiktok)"
                style="cursor: pointer"
                alt=""
                src="../../assets/images/tiktok.svg"
              />
              <img
                v-if="editUser.socials.youtube"
                style="cursor: pointer"
                @click="openLink(editUser.socials.youtube)"
                alt=""
                src="../../assets/images/youtube.svg"
              />
            </div>
          </v-row>
          <v-row v-if="editUser.occupation && !Object.values(editUser.occupation).every((x) => x === '')" class="user-field-row">
            <p class="field-name">{{ $t("Occupation") }}</p>

           <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="occupation of Object.values(editUser.occupation).filter((x) => x !== '')"
                  :key="occupation"
                  class="content-normal"
                  style="width: 100%; flex-wrap: nowrap"
                >
                  <p style="max-width: 244px">{{ occupation }}</p>
                </div>
              </div>

          </v-row>
          <v-row
            v-if="
              editUser.profilePicture &&
              editUser.profilePicture.length &&
              editUser.profilePicture[0].name
            "
            class="user-field-row"
            id="lightgallery-user"
          >
            <p class="field-name">{{ $t("Image") }}</p>
            <img
              @click="openImageGallery"
              class="profile-picture"
              :src="editUser.profilePicture[0].name"
              alt=""
            />
          </v-row>
          <!--    <v-row
            v-if="haveKv24Account"
            class="user-field-row"
          >
            <p class="field-name">{{ $t("kv24_username") }}</p>
            <p class="field-value">
              {{
                editUser.listingAccounts.filter(
                    (account) => account.platform === "kv24"
                )[0].email
              }}
            </p>
          </v-row> -->

         <!--  <v-row
              v-if="
              editUser.profilePicture &&
              editUser.profilePicture.length &&
              editUser.profilePicture[0].name
            "
              id="lightgallery-user"
              style="margin-top: 24px"
          >
            <img
                @click="openImageGallery"
                class="profile-picture"
                :src="editUser.profilePicture[0].name"
                alt=""
            />
          </v-row> -->
          <v-row v-if="editUser.about && !Object.values(editUser.about).every((x) => x === '')" class="user-field-row">

            <p class="field-name">{{ $t("Description") }}</p>

             <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="desc of Object.values(editUser.about).filter((x) => x !== '')"
                  :key="desc"
                  class="content-normal"
                  style="width: 100%; flex-wrap: nowrap"
                >
                  <p style="max-width: 244px">{{ desc }}</p>
                </div>
              </div>
          </v-row>
        </div>
        <v-row
          style="height: 1px; background: #e6e8ec; margin-top: 24px"
        ></v-row>
        <BaseAccordion
          :title="$t('MyFeedback')"
          :separator-line="true"
          :identifier="'feedback'"
          :show-bot-divider="true"
          :parent-name="'brokerSettings'"
          :buttons-open-accordion="true"
          :padding-top="'24px'"
          :padding-bottom="'24px'"
          :button-name="$t('Edit')"
          :button-icon="'edit_16_16.svg'"
          @buttonPressed="editUserButton(editUser, true, false)"
        >
          <div style="padding-bottom: 24px" class="content" v-if="!editUser.clients">
            {{ this.$t("NoFeedback") }}
          </div>
          <v-row v-if="editUser.clients && feedback.length">
            <div class="description content" style="margin-top: 0px; padding-bottom: 24px">
              <v-row
                class="description-item"
                style="margin-top: 0px; padding-top: 0px"
              >
                <textarea
                  style="auto-scale-text;"
                  outlined
                  rows="3"
                  id="text-area"
                  v-if="!showFullText"
                  readonly
                  class="description-item1 content"
                  v-model="feedback"
                >
                </textarea>
              </v-row>
              <div
                class="show-more-button"
                v-bind:class="{ hidden: showFullText }"
              >
                <button
                  style="text-decoration-line: underline"
                  for="expanded"
                  @click="showText()"
                  id="textButton"
                >
                  <p
                    v-if="
                      editUser.clients &&
                      (feedback.length > 456 || this.rowCount > 5)
                    "
                    class="link"
                  >
                    {{ $t("ShowMore") }}
                  </p>
                </button>
              </div>
              <v-row style="margin-top: 0px; width: 477px">
                <v-textarea
                  hide-details
                  v-if="showFullText"
                  name="input-7-1"
                  auto-grow
                  readonly
                  color="black"
                  class="description-textarea content"
                  v-model="feedback"
                ></v-textarea>
              </v-row>
              <div
                class="show-less-button"
                v-bind:class="{ hidden: !showFullText }"
              >
                <button
                  style="padding-left: 0px; text-decoration-line: underline"
                  for="expanded"
                  @click="showTextLess()"
                  id="textButton"
                >
                  <p class="link">
                    {{ $t("ShowLess") }}
                  </p>
                </button>
              </div>
            </div>
          </v-row>
        </BaseAccordion>
        <BaseAccordion
        :title="$t('Achievements')"
        :separator-line="true"
        :identifier="'achievements'"
        :parent-name="'brokerSettings'"
        :buttons-open-accordion="true"
        :show-bot-divider="false"
        :padding-top="'24px'"
        :padding-bottom="'24px'"
        :button-name="$t('Edit')"
        :button-icon="'edit_16_16.svg'"
        @buttonPressed="editUserButton(editUser, false, true)"
      >
        <div class="content" style="padding-bottom: 24px" v-if="!editUser.achievements">
          {{this.$t("NoAchievements")}}
        </div>
        <div style="padding-bottom: 24px;" v-if="editUser.achievements">
          <div style="display: flex; flex-direction: column; gap: 12px">
                <div
                  v-for="achievement of Object.values(editUser.achievements).filter((x) => x !== '')"
                  :key="achievement"
                  class="content-normal"
                  style="width: 100%; flex-wrap: wrap; word-break: break-word"
                >
                  <p style="max-width: 477px">{{ achievement }}</p>
                </div>
              </div>
        </div>
      </BaseAccordion>
      <v-row
        style="height: 1px; background: #e6e8ec;"
      ></v-row>
      <BaseAccordion
        :title="$t('ListingLastImage')"
        :separator-line="true"
        :identifier="'listingLastImage'"
        :parent-name="'brokerSettings'"
        :button-opens-accordion="true"
        :padding-top="'24px'"
        :padding-bottom="'24px'"
      >
        <div style="max-width: 251px">
          <ImageUpload
            :maxAmountOfImages="1"
            @change="changeImage"
            :can-delete="true"
            :images="brokerLastImage"
            :label="$t('UploadImage')"
            :compress="true"
            :drag-and-drop="true"
          />
        </div>
      </BaseAccordion>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import BaseAccordion from "@/components/common/BaseAccordion";
import ImageUpload from "@/components/common/ImageUpload";
import Admin from "@/http/admin";
import EditButton from "@/components/common/EditButton";
import {mapGetters} from "vuex";

export default {
  name: "UserSidepanel",
  props: ["userId"],
  components: {
    EditButton,
    BaseAccordion,
    ImageUpload
  },
  data: () => ({
    editUser: null,
    userLoaded: false,
    invitationSent: false,
    /* showFeedbackField: false,
    showAchievementsField: false, */
    showFullText: false,
    rowCount: 0,
    brokerLastImage: [],
  }),
  created() {
    this.getUser();
    console.log(this.allowedToEdit)
  },
  mounted() {
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.key === "Escape") {
        this.closePanel();
        document.onkeydown = null;
      }
    };
    document.documentElement.style.overflow = "hidden";
  },
  beforeDestroy() {
    document.onkeydown = null;
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["user", "users"]),
    haveKv24Account() {
      return this.editUser.listingAccounts.some(a => a.platform === "kv24")
    },
    allowedToEdit() {
      if (this.user.access.accessLevel === "company_admin") {
        return true;
      } else if (this.user.access.accessLevel === "group_admin") {
        if (this.user._id === this.userId || !this.users.find((user) => user._id === this.userId).isAdmin) {
          return true;
        }
      }
      return false;
    },
    feedback() {
      let result = []
      for (let value of Object.values(this.editUser.clients)) {
        result.push(value)
      }
      return result.join("\r\n\n");
    },
  },
  watch: {
    userLoaded() {
      this.stickyHeader();
    },
  },
  methods: {
    async inviteAgain() {
      const response = await axios.post(
          `/api/colleague/invite-again/${this.userId}`
      );
      if (response.status === 201) {
        this.toastSuccess(
            this.$t("Invitation") + " " + this.$t("sent").toLowerCase()
        );
        this.invitationSent = true;
      } else {
        this.toastError(this.$t("ErrorSendingInvitation"));
      }
    },
    async requestPasswordReset() {
      const response = await axios.post(
          `/api/colleague/reset-password/${this.userId}`
      );
      if (response.status === 201) {
        this.toastSuccess(
            this.$t("Request") + " " + this.$t("sent").toLowerCase()
        );
        this.invitationSent = true;
      } else {
        this.toastError(this.$t("ErrorSendingInvitation"));
      }
    },
    async getUser() {
      const response = await axios.get(`/api/user/${this.userId}`);
      this.editUser = response.data.user;
      this.brokerLastImage = this.editUser.brokerLstImage;
      this.userLoaded = true;
      this.checkRows();
    },
    editUserButton(user, feedbackField, achievementsField) {
      this.$emit("editUser", user, feedbackField, achievementsField);
    },
    openImageGallery() {
      let dynamicEl = [];
      dynamicEl.push({src: this.editUser.profilePicture[0].name});
      let imageGallery = null;
      const imageWrapper = document.getElementById("lightgallery-user");
      if (imageWrapper) {
        imageGallery = window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
        });
      }
      console.log(imageGallery);

      console.log(imageGallery);
    },
    formatUserRole(user) {
      if (user.isAdmin) {
        return "Admin";
      } else if (user.isGroupAdmin) {
        return this.$t("GroupAdmin");
      } else {
        return this.$t("User");
      }
    },
    formatUserObject(object) {
      let result = [];
      for (const key in object) {
        if (object[key].value) {
          result.push(this.$t(key));
        }
        if (object[key] === 1) {
          result.push(this.$t(key.toUpperCase()));
        }
      }
      return result.join(", ");
    }, 
    formatDefaultGroup(groups) {
      let result = []
      groups.forEach((group) => {
        if (group.userDefault) {
          result.push(group.name)
        }
      })
      return result[0] ? result[0] : "-";
    },
    formatAdminInGroups(groups) {
      let result = []
      groups.forEach((group) => {
        if (group.isGroupAdmin) {
          result.push(group.name)
        }
      })
      return result[0] ? result.join(", ") : "-";
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    showText() {
      this.showFullText = true;
    },
    showTextLess() {
      this.showFullText = false;
    },
    checkRows() {
      if (this.user.feedback) {
        var el = this.user.feedback;
        var lineCount = 0;
        var lines = el.split("\n");
        for (var i = 0; i < lines.length; i++) {
          if (lines[i].length > 0) {
            lineCount++;
          }
        }
        this.rowCount = lineCount;
      }
    },
    formatGroupField(user) {
      let result = [];
      for (let group of user.groups) {
        result.push(group.name);
      }
      return result.join(", ");
    },
    closePanel(e) {
      if (!e) {
        this.$emit("overlayClicked");
      }
      if (e.target !== e.currentTarget) return;
      this.$emit("overlayClicked");
    },
    async changeImage(img) {
      this.brokerLastImage = img;
      await Admin.saveBrokerLstImage(this.brokerLastImage, this.userId);
    },
    async stickyHeader() {
      let headerHeight = null;
      setTimeout(() => {
        headerHeight = document.getElementById("userpanel-header").offsetHeight;
        let marginTop = headerHeight + "px";
        const checkForBodyLoading = setInterval(() => {
          let bodyElement = document.getElementById("user-body");
          if (bodyElement) {
            document.getElementById("user-body").style.marginTop = marginTop;
            clearInterval(checkForBodyLoading);
          }
        }, 100);
        console.log(headerHeight);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.user-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 7;
  width: 560px;
  overflow-x: hidden;
  overflow-y: scroll;
  border: 1px solid #e6e8ec;
}

.user-panel-header {
  display: flex;
  position: fixed;
  background: white;
  flex-direction: column;
  padding: 32px 32px 32px;
  width: 560px;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
    /*overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;*/
  }
}

.user-panel-body {
  padding: 32px 32px 64px;
  overflow: hidden;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.user-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}

.grey-950 {
  color: #75787a;
}

.user-status-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  color: white;
  height: 20px;
  max-width: fit-content;
  border-radius: 4px;
  cursor: default;
  white-space: nowrap;
}

.main-info-title {
  margin-bottom: 24px;
}

.profile-picture {
  max-width: 130px;
  height: 130px;
  cursor: pointer;
  border-radius: 8px;
}

.re-invite-button {
  cursor: pointer;
}
.v-text-field {
  padding: 0;
}

.description {
  /* padding-left: 24px;
  padding-top: 0px;
  padding-bottom: 24px;
  padding-right: 56px; */
  border: none !important;
  outline: none !important;
  width: 100%;
  min-height: 128px;
  resize: none;
}
.description-item {
  text-overflow: ellipsis;
}

.description-item1 {
  border: none !important;
  outline: none !important;
  width: 656px;
  height: 100px;
  resize: none;
  padding-bottom: 58px;
  padding-top: 0px;
  white-space: pre-line;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show-more-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
.show-less-button {
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  width: 0%;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:after {
  display: none;
}

::v-deep .v-textarea textarea {
  line-height: unset;
  color: black !important;
  width: 477px;
}
.hidden {
  display: none;
}
.white-space {
  white-space: pre-line;
}
</style>
