var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user.access.accessLevel === 'company_admin' || _vm.user.access.accessLevel === 'group_admin' ? _c('div', [_vm.users ? _c('Header', {
    on: {
      "addUserPanel": _vm.addUserPanel,
      "viewChanged": _vm.changeView,
      "searchInput": _vm.changeSearch,
      "onlyActiveUsers": _vm.activeUsersOnly
    }
  }) : _vm._e(), _c('Content', {
    attrs: {
      "add-user-panel-open": _vm.addUserPanelOpen,
      "active-view": _vm.activeView,
      "search": _vm.searchInput,
      "updated": _vm.userUpdated,
      "active-users-toggle": _vm.activeUserToggle
    },
    on: {
      "userSidepanelClosed": _vm.userSidepanelClose
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }