<template>
  <div v-if="user.access.accessLevel === 'company_admin' || user.access.accessLevel === 'group_admin'">
    <Header
      v-if="users"
      @addUserPanel="addUserPanel"
      @viewChanged="changeView"
      @searchInput="changeSearch"
      @onlyActiveUsers="activeUsersOnly"
    />
    <Content
      @userSidepanelClosed="userSidepanelClose"
      :add-user-panel-open="addUserPanelOpen"
      :active-view="activeView"
      :search="searchInput"
      :updated="userUpdated"
      :active-users-toggle="activeUserToggle"
    />
  </div>
</template>

<script>
import Header from "@/components/Admin/Header.vue";
import Content from "@/components/Admin/Content.vue";
import { mapGetters } from "vuex";
export default {
  name: "Admin",
  components: {
    Header,
    Content,
  },
  data() {
    return {
      activeView: "Users",
      searchInput: "",
      userUpdated: 0,
      addUserPanelOpen: false,
      userSidepanelClosed: true,
      activeUserToggle: null,
    };
  },
  mounted() {},
  created() {
  },
  computed: {
    ...mapGetters(["users", "user"]),
  },
  methods: {
    activeUsersOnly(boolean) {
      this.activeUserToggle = boolean;
    },
    changeView(view) {
      this.activeView = view;
    },
    changeSearch(val) {
      this.searchInput = val;
    },
    addUserPanel(value) {
      this.addUserPanelOpen = value;
    },
    userSidepanelClose() {
      this.addUserPanelOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
