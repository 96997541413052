<template>
  <div>
    <v-row class="watermark-upload-row">
      <v-col style="max-width: 120px">
        <div style="display: flex; height: fit-content; gap: 16px">
          <input
              type="file"
              ref="watermarkInput"
              id="file-upload-input"
              style="cursor: pointer"
              @change="uploadWatermark"
              hidden
          />
          <div
              @click="() => $refs.watermarkInput.click()"
              style="
                  padding: 4px 8px;
                  cursor: pointer;
                  border: 1px dashed #c3c4c6;
                  height: 24px;
                  gap: 6px;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                "
          >
            <img
                height="16"
                :src="require('@/assets/images/image.svg')"
                alt=""
            />
            <label style="width: 100%; cursor: pointer; font-size: 12px">{{
                $t("Upload")
              }}</label>
          </div>
          <p class="content-normal" v-if="watermark.name">
            {{ watermark.name }}
          </p>
          <img v-if="watermarkUploaded"
              src="../../assets/images/action-delete.svg"
              alt=""
              style="cursor: pointer"
              @click="deleteWatermark"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="watermark-preview-row">
      <v-col class="watermark-opacity-column">
        <BaseInput
            :placeholder="$t('Opacity')"
            :icon-right="'percentage.svg'"
            v-model="watermark.opacity"
            type="number"
        ></BaseInput>
        <submit-button
            class="save-watermark-button"
            @clicked="saveWatermark"
        >
        </submit-button>
      </v-col>
      <v-col>
        <div class="preview-container"
             style="cursor: pointer"
             @click="openWatermarkPositionModal"
        >
          <p class="preview-container_text">{{$t("Preview")}}</p>
          <LogoPositionModal
              ref="logoPositionModal"
              v-bind:class="switchClass"
              :watermarkUpdated="watermarkUpdater"
              :watermark="watermark"
              :preview="preview"
              :companyId="companyId"
              @close-modal="closeWatermarkPositionModal"
              @updated="updateWatermark"
              @watermarkChanged="changeStatusUpdater"
              :opacity="watermark.opacity"
              :companyWatermark="companyWatermark"
              :watermarkChanged="watermarkChanged"
          >
          </LogoPositionModal>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LogoPositionModal from "./logoPositionModal";
import axios from "axios";
import BaseInput from "../common/BaseInput";
import SubmitButton from "@/components/common/SubmitButton";
export default {
  name: "WatermarkEditing",
  components: {
    SubmitButton,
    BaseInput,
    LogoPositionModal
  },
  props: [
    "company"
  ],
  data() {
    return {
      selectedFile: null,
      watermarkChanged: false,
      preview: true,
      watermarkUpdater: 0,
      showLogoPositionModal: false,
      watermarkUploaded: false,
      watermark: {
        name: "",
        imgUrl: "",
        positions: {
          x: 0,
          y: 0,
          snap: "top-left"
        },
        size: {
          width: 0,
          height: 0
        },
        opacity: 0,
      },
      companyId: this.company._id,
      companyWatermark: this.company.logo,
    }
  },
  computed: {
    switchClass() {
      return this.preview ? 'watermark-preview' : 'watermark-modal'
    },
  },
  async created() {
    await this.getWatermarkPosition()
  },
  methods: {
    saveWatermark() {
      this.$refs.logoPositionModal.saveWatermark()
      this.toastSuccess(this.$t("WatermarkSaved"));
    },
    changeStatusUpdater(event) {
      this.watermarkChanged = event
    },
    openWatermarkPositionModal() {
      this.showLogoPositionModal = true
      this.preview = false
    },
    closeWatermarkPositionModal() {
      this.showLogoPositionModal = false
      this.preview = true
    },
    async deleteWatermark() {
      let requestBody = {companyId: this.company._id}
      this.watermark.name = "";
      this.watermarkUploaded = false
      await axios.post("/api/company/remove-logo", requestBody)
    },
    updateWatermark(updatedWatermark) {
      this.watermark = {
        name: updatedWatermark.name,
        imgUrl: updatedWatermark.image,
        positions: {
          x: updatedWatermark.imageSpecifics.x,
          y: updatedWatermark.imageSpecifics.y,
          snap: updatedWatermark.imageSpecifics.snap
        },
        size: {
          width: updatedWatermark.imageSpecifics.width,
          height: updatedWatermark.imageSpecifics.height
        },
        opacity: updatedWatermark.imageSpecifics.opacity
      }
    },
    async putWatermark(e) {
      this.$refs.uploader.resetWithoutEmitting();
      this.watermark.imgUrl = e[0].name;
      this.watermark.positions.x = 0
      this.watermark.positions.y = 0
      await this.getImageSizeFromBase64(e[0].name);
      this.watermarkUploaded = true
      this.watermarkChanged = true
      this.watermarkUpdater++
    },
    async uploadWatermark(event) {
      if (event && event.target && event.target.files[0]) {
        const imageFile = event.target.files[0];
        if (
            imageFile.type === "image/jpeg" ||
            imageFile.type === "image/png" ||
            imageFile.type === "image/svg+xml"
        ) {
          const imageSize = await this.getImageSize(imageFile);
          const imageBase64 = await this.imageToBase64(imageFile);
          this.watermark.imgUrl = imageBase64;
          this.selectedFile = imageFile;
          this.watermark.name = imageFile.name;
          this.watermark.positions.x = 0
          this.watermark.positions.y = 0
          this.watermark.size.width = imageSize.width;
          this.watermark.size.height = imageSize.height
          this.watermarkUploaded = true
          this.watermarkChanged = true
          this.watermarkUpdater++
        }
      }
    },
    getImageSize(file) {
      const img = document.createElement("img");
      const promise = new Promise((resolve, reject) => {
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          resolve({ width, height });
        };

        img.onerror = reject;
      });
      img.src = window.URL.createObjectURL(file);
      return promise;
    },
    imageToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    getWatermarkPosition() {
      if(this.companyWatermark) {
        this.watermark.name = this.companyWatermark.name;
        this.watermark.imgUrl = this.companyWatermark.imgUrl.watermarkOrig;
        this.watermark.positions.x = this.companyWatermark.positions.x;
        this.watermark.positions.y = this.companyWatermark.positions.y;
        this.watermark.positions.snap = this.companyWatermark.positions.snap;
        this.watermark.size.width = this.companyWatermark.size.width;
        this.watermark.size.height = this.companyWatermark.size.height;
        this.watermark.opacity = this.companyWatermark.opacity
        this.watermarkUploaded = true
      }
    },
    async getImageSizeFromBase64(base64) {
      let img = new Image();
      img.src = base64;

      await new Promise((resolve, reject) => {
        try {
          img.onload = () => {
            let width;
            let height;
            width = img.width;
            height = img.height;
            if(img.width > 500) {
              width = width / 2;
              height = height / 2;
            }
            this.watermark.size = {
              width: width,
              height: height
            }
            resolve();
          };
        } catch (e) {
          reject(e)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.watermark-upload-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.watermark-image-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.watermark-opacity-column {
  max-width: 35%;
  max-height: 150px;
}
.image-uploader {
  max-height: 30px;
  width: 100%;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;

  margin: 0;
  ::v-deep .beforeUpload .icon {
    height: 16px;
    width: auto;
    margin: 0 8px;
  }
  ::v-deep .beforeUpload {
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 !important;
  }
  ::v-deep .beforeUpload .mainMessage {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #000000;
  }
  ::v-deep .clearButton {
    display: none;
  }
}
.preview-container {
  position: relative;
  height: 150px;
  width: 225px;
}
.preview-container_image {
  position: absolute;
  z-index: 0;
  height: 150px;
  width: 225px;
}
.preview-container_watermark {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  height: 20%;
}
::v-deep .imgsPreview .imageHolder {
  display: none;
}
.watermark-preview {
  transform: translate(-112px, -50%) scale(0.132) !important;
  transform-origin: left;
  position: relative;
}
</style>